var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-page-layout',{attrs:{"title":"Gerenciar Usuários"}},[_c('mf-card-container',{attrs:{"placeholder":"Busca (nome ou login)","use-search":"","title":"Listagem de usuários","description":'Listagem de todos os usuários cadastrados na base'},scopedSlots:_vm._u([{key:"headers",fn:function(){return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('mf-button',{attrs:{"disabled":_vm.disableButton,"color":"primary","label":"Configurar Permissões"},on:{"click":_vm.openActionsRoles}})],1),_c('v-col',{staticClass:"ml-2",attrs:{"cols":"auto"}},[_c('mf-button',{staticClass:"mr-3",attrs:{"disabled":!_vm.selected.length,"color":"primary","label":"Painel de Ações"},on:{"click":function($event){_vm.actionsPanelDialog = true}}})],1)],1)]},proxy:true},{key:"filters",fn:function(){return [_c('v-menu',{attrs:{"right":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{attrs:{"color":"primary","value":_vm.filtersQuantity,"content":_vm.filtersQuantity,"overlap":""}},[_c('v-icon',_vm._g({},on),[_vm._v("mdi-filter")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"pa-4",attrs:{"width":"285px"}},[_c('mf-select',{attrs:{"items":['Interno', 'Externo'],"label":"Tipo de usuário","outlined":""},model:{value:(_vm.mf),callback:function ($$v) {_vm.mf=$$v},expression:"mf"}}),_c('mf-select',{attrs:{"label":"Role","item-value":"value","item-text":"text","items":[
              { text: 'Dev', value: 'DEV' },
              { text: 'Manager', value: 'MANAGER' },
              { text: 'Support', value: 'SUPPORT' },
              { text: 'Deployment', value: 'DEPLOYMENT' },
              { text: 'Analyst', value: 'ANALYST' }
            ],"outlined":""},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('mf-toggle',{staticClass:"mt-0 pt-0 mx-2",attrs:{"label":"Mostar Inativos"},on:{"change":_vm.refetchUsers},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}}),_c('mf-button',{staticClass:"mx-auto",attrs:{"color":"primary","label":"Limpar Filtros","outlined":""},on:{"click":_vm.cleanFilters}})],1)],1)]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-data-table',{attrs:{"item-key":"login","show-select":"","single-select":false,"headers":_vm.headers,"loading":_vm.$apollo.queries.adminUsers.loading,"items":_vm.users,"search":_vm.search},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.role ? _vm.formatFirstUpperCase(item.role) : '-')+" ")]}},{key:"item.active",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.active ? 'success' : 'error'}},[_vm._v(_vm._s(item.active ? 'check_circle' : 'error'))])]}},{key:"item.mf",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.mf ? 'Interno' : 'Externo')+" ")]}},{key:"item.access.has_platform_access",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.access && item.access.has_platform_access ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.access && item.access.has_platform_access ? 'mdi-lock-open-variant' : 'mdi-lock')+" ")])]}},{key:"item.access.has_admin_access",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.access && item.access.has_admin_access ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.access && item.access.has_admin_access ? 'mdi-lock-open-variant' : 'mdi-lock')+" ")])]}},{key:"item.session",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("more_vert")])]}}],null,true)},[_c('v-list',{staticClass:"ma-0 pa-0"},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openSessionDetails(item._id)}}},[_c('v-list-item-title',{staticClass:"body-2"},[_c('v-icon',{staticClass:"mb-1 mr-1",attrs:{"small":""}},[_vm._v("mdi-cog")]),_vm._v(" Detalhes")],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('session',{attrs:{"dialog":_vm.sessionDetailsModal,"session":_vm.userSession},on:{"update:session":function($event){_vm.userSession=$event},"close":_vm.closeSessionDetails}}),_c('actions-panel',{attrs:{"dialog":_vm.actionsPanelDialog,"selected":_vm.selected},on:{"close":_vm.closeActions}}),_c('actions-roles',{attrs:{"dialog":_vm.actionsRolesDialog,"selected":_vm.selected},on:{"close":_vm.closeActions}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }