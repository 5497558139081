<template>
  <base-page-layout title="Gerenciar Usuários">
    <mf-card-container
      v-model="search"
      placeholder="Busca (nome ou login)"
      use-search
      title="Listagem de usuários"
      :description="'Listagem de todos os usuários cadastrados na base'"
    >
      <template #headers>
        <v-row justify="end">
          <v-col cols="auto">
            <mf-button :disabled="disableButton" color="primary" label="Configurar Permissões" @click="openActionsRoles"></mf-button>
          </v-col>
          <v-col class="ml-2" cols="auto">
            <mf-button :disabled="!selected.length" color="primary" label="Painel de Ações" class="mr-3" @click="actionsPanelDialog = true"></mf-button>
          </v-col>
        </v-row>
      </template>
      <template #filters>
        <v-menu v-model="menu" right :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-badge color="primary" :value="filtersQuantity" :content="filtersQuantity" overlap>
              <v-icon v-on="on">mdi-filter</v-icon>
            </v-badge>
          </template>
          <v-card width="285px" class="pa-4">
            <mf-select v-model="mf" :items="['Interno', 'Externo']" label="Tipo de usuário" outlined />
            <mf-select
              v-model="role"
              label="Role"
              item-value="value"
              item-text="text"
              :items="[
                { text: 'Dev', value: 'DEV' },
                { text: 'Manager', value: 'MANAGER' },
                { text: 'Support', value: 'SUPPORT' },
                { text: 'Deployment', value: 'DEPLOYMENT' },
                { text: 'Analyst', value: 'ANALYST' }
              ]"
              outlined
            />
            <mf-toggle v-model="active" class="mt-0 pt-0 mx-2" label="Mostar Inativos" @change="refetchUsers" />
            <mf-button class="mx-auto" color="primary" label="Limpar Filtros" outlined @click="cleanFilters" />
          </v-card>
        </v-menu>
      </template>
      <v-data-table
        v-model="selected"
        item-key="login"
        show-select
        :single-select="false"
        :headers="headers"
        :loading="$apollo.queries.adminUsers.loading"
        :items="users"
        :search="search"
      >
        <template v-slot:[`item.role`]="{ item }">
          {{ item.role ? formatFirstUpperCase(item.role) : '-' }}
        </template>
        <template v-slot:[`item.active`]="{ item }">
          <v-icon :color="item.active ? 'success' : 'error'">{{ item.active ? 'check_circle' : 'error' }}</v-icon>
        </template>
        <template v-slot:[`item.mf`]="{ item }">
          {{ item.mf ? 'Interno' : 'Externo' }}
        </template>
        <template v-slot:[`item.access.has_platform_access`]="{ item }">
          <v-icon :color="item.access && item.access.has_platform_access ? 'success' : 'error'">
            {{ item.access && item.access.has_platform_access ? 'mdi-lock-open-variant' : 'mdi-lock' }}
          </v-icon>
        </template>
        <template v-slot:[`item.access.has_admin_access`]="{ item }">
          <v-icon :color="item.access && item.access.has_admin_access ? 'success' : 'error'">
            {{ item.access && item.access.has_admin_access ? 'mdi-lock-open-variant' : 'mdi-lock' }}
          </v-icon>
        </template>
        <template v-slot:[`item.session`]="{ item }">
          <v-menu bottom transition="slide-x-transition">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">more_vert</v-icon>
            </template>
            <v-list class="ma-0 pa-0">
              <v-list-item link @click="openSessionDetails(item._id)">
                <v-list-item-title class="body-2"><v-icon class="mb-1 mr-1" small>mdi-cog</v-icon> Detalhes</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </mf-card-container>

    <!-- components -->
    <session :dialog="sessionDetailsModal" :session.sync="userSession" @close="closeSessionDetails" />
    <actions-panel :dialog="actionsPanelDialog" :selected="selected" @close="closeActions" />
    <actions-roles :dialog="actionsRolesDialog" :selected="selected" @close="closeActions" />
  </base-page-layout>
</template>

<script>
import { QUERY_GET_INTERNAL_USERS, QUERY_GET_USER_SESSIONS } from '@/modules/session/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout'),
    Session: () => import('@/modules/session/Session'),
    ActionsPanel: () => import('@/modules/session/components/ActionsPanel'),
    ActionsRoles: () => import('@/modules/session/components/ActionsRoles')
  },
  data: () => ({
    search: '',
    users: [],
    sessionDetailsModal: false,
    userSession: null,
    selected: [],
    actionsPanelDialog: false,
    actionsRolesDialog: false,
    mf: '',
    active: false,
    role: '',
    menu: false,
    filtersQuantity: 0
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Usuário',
          value: 'name',
          width: '20%'
        },
        {
          text: 'Login',
          value: 'login',
          width: '20%'
        },
        {
          text: 'Role',
          value: 'role',
          width: '10%'
        },
        {
          text: 'Ativo',
          value: 'active',
          width: '10%'
        },
        {
          text: 'Tipo do Usuário',
          value: 'mf',
          width: '15%'
        },
        {
          text: 'Plataforma',
          value: 'access.has_platform_access',
          width: '10%'
        },
        {
          text: 'Starlord',
          value: 'access.has_admin_access',
          width: '20%'
        },
        {
          text: '',
          value: 'session',
          width: '5%',
          align: 'center',
          sortable: false
        }
      ]
    },
    disableButton() {
      let filtered = this.selected.filter(item => item.mf)
      return !this.selected.length || !filtered.length
    }
  },
  apollo: {
    adminUsers: {
      query: QUERY_GET_INTERNAL_USERS,
      fetchPolicy: 'network-only',
      variables() {
        let queryParams = {}
        if (this.mf) queryParams.mf = this.mf === 'Interno'
        if (this.role) queryParams.role = this.role
        if (!this.active) queryParams.active = true
        return queryParams
      },
      update({ adminUsers }) {
        this.users = adminUsers
      }
    }
  },
  watch: {
    mf() {
      this.refetchUsers()
    },
    role() {
      this.refetchUsers()
    }
  },
  methods: {
    async openSessionDetails(id) {
      this.userSession = await this.getUserSessions(id)
      this.sessionDetailsModal = true
    },
    closeSessionDetails() {
      this.userSession = null
      this.sessionDetailsModal = false
    },
    async getUserSessions(id) {
      try {
        const { data } = await this.$apollo.query({
          query: QUERY_GET_USER_SESSIONS,
          variables: {
            user_id: id
          }
        })
        return data.userSessions
      } catch (err) {
        this.sessionDetailsModal = false
        this.$snackbar({ message: 'Falha ao buscar sessões do usuário' })
      }
    },
    closeActions() {
      this.actionsPanelDialog = false
      this.actionsRolesDialog = false
      this.selected = []
      this.$apollo.queries.adminUsers.refetch()
    },
    openActionsRoles() {
      const onlyMFSelection = []
      this.selected.map(user => {
        if (user.mf) onlyMFSelection.push(user)
      })
      this.selected = onlyMFSelection
      if (!onlyMFSelection.length) return this.$snackbar({ message: 'Opção disponível apenas para usuários internos' })
      this.actionsRolesDialog = true
    },
    refetchUsers() {
      let filters = 0
      if (this.mf) filters += 1
      if (this.role) filters += 1
      if (this.active) filters += 1
      this.filtersQuantity = filters
      this.$apollo.queries.adminUsers.refetch()
    },
    cleanFilters() {
      this.filtersQuantity = 0
      this.mf = ''
      this.role = ''
      this.active = false
    },
    formatFirstUpperCase(value) {
      const lowerCase = value.toLowerCase()
      return lowerCase.replace(/^./, lowerCase[0].toUpperCase())
    }
  }
}
</script>
